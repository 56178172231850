<template>
    <div>
      <h2>Upload an Image</h2>
      <input type="file" @change="handleFileChange" />
      <!-- <button @click="uploadImage">Upload</button> -->
      <button v-if="imageUrl">Upload</button>
      <p v-if="progress">Progress: {{ progress }}%</p>
      <input v-model="imagePath" placeholder="Enter image path" class="hidden" />
      <p v-if="message">{{ message }}</p>
      <button @click="deleteImage">Delete Image</button>
      <p v-if="imageUrl">Uploaded Image URL: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a></p>

      <img :src="imageUrl" alt="">
    </div>
  </template>

<script>
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

export default {
  data() {
    return {
      file: null,
      progress: 0,
      imageUrl: "",
      imagePath: "", // Path of the image in Firebase Storage
      message: "",
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    // },
    // uploadImage() {
      if (!this.file) {
        alert("Please select a file first!");
        return;
      }

      // Create a reference to Firebase Storage
      const storageRef = ref(storage, `images/providers/${this.file.name}`);

      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, this.file);

      // Monitor upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress.toFixed(0); // Update progress
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          this.imageUrl = url; // Save the image URL
          this.imagePath = url; // Set the input field
        }
      );
    },
    async deleteImage() {
      if (!this.imagePath) {
        this.message = "Please enter the image path.";
        return;
      }

      // Create a reference to the file to delete
      const fileRef = ref(storage, this.imagePath);

      try {
        // Delete the file
        await deleteObject(fileRef);
        this.message = "Image deleted successfully.";
        this.imagePath = ""; // Reset the input field
      } catch (error) {
        console.error("Error deleting the image:", error);
        this.message = "Failed to delete the image.";
      }
    },
  
  }
};
</script>

<style scoped>
h2 {
  margin-bottom: 10px;
}
input {
  display: block;
  margin-bottom: 10px;
}
button {
  margin-bottom: 10px;
}
</style>
